import { post } from '../../lib/request'

export const createCompletion = async (messages) => {
  const body = {
    model: 'gpt-3.5-turbo',
    temperature: 0.2,
    messages,
  }

  console.log('Sumitted: ', JSON.stringify(body, null, 2))
  console.log('Waiting for response...')

  const response = await post('/chat/completions', body)

  return response
}
