import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material'
import {
  InsertEmoticon as InsertEmoticonIcon,
  SmartToy as SmartToyIcon,
} from '@mui/icons-material'


const MessageItem = ({role, content, index}) => {
  const isUser = role === 'user'
  const humanizedRole = isUser ? 'You' : 'OpenAI'
  const avatarIcon = isUser ? <InsertEmoticonIcon /> : <SmartToyIcon />

  return (
    <ListItem
      key={`item-message-${index}`}
      alignItems="flex-start"
    >
      <ListItemAvatar>
        <Avatar>
          {avatarIcon}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={humanizedRole}
        secondary={content}
        sx={{
          whiteSpace: 'pre-wrap'
        }}
      />
    </ListItem>
  )
}

function Conversation({messages, scrollRef}) {
  const list = messages.map(({role, content}, index) => (
    <MessageItem
      index={index}
      role={role}
      content={content}
    />
  ))

  return (
    <List
      sx={{
        maxHeight: 320,
        overflow: 'auto',
      }}
      subheader={<li />}
    >
      {list}
      <li ref={scrollRef} />
    </List>
  )
}

export default Conversation
