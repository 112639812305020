import { useEffect, useState } from 'react'
import {
  Container,
  Grid,
  Typography,
} from '@mui/material'

import { db } from 'lib/firebase'
import { onValue, ref } from 'firebase/database'

import ProjectCard from './ProjectCard'

function Projects() {
  const [projects, setProjects] = useState([])

  useEffect(() => {
    const query = ref(db, "/projects")

    return onValue(query, (snapshot) => {
      const data = snapshot.val()

      if (snapshot.exists()) setProjects(data)
    })
  }, [])

  if (!projects.length) return null

  return (
    <Container
      component="section"
      id="projects"
      sx={{ pt: 10, pb: 10 }}
    >
      <Typography gutterBottom variant="h4">
        Other projects
      </Typography>
      <Typography variant="body1" sx={{ pb: 5 }}>
        Explore some of the other things I do in my free time.
      </Typography>

      <Grid
        container
        alignItems="stretch"
        justifyContent="center"
        spacing={5}
      >
        {projects
          .filter(({ display }) => display)
          .map(({ actions, description, image, title, url })=> (
            <Grid item md={4} sm={6} xs={12} key={title}>
              <ProjectCard
                actions={actions}
                description={description}
                image={image}
                title={title}
                url={url}
              />
            </Grid>
          ))
        }
      </Grid>
    </Container>
  )
}

export default Projects
