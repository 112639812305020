import React, { useEffect, useRef } from 'react'

import Particle from './Particle'

const Rain = () => {
  const canvasRef = useRef(null)
  const numberOfParticles = 30
  let particles = []

  useEffect(() => {
    const canvas = canvasRef.current
    const ctx = canvas.getContext('2d')

    function createParticles() {
      for (let i = 0; i < numberOfParticles; i++) {
        particles.push(new Particle({ canvas, ctx }))
      }
    }

    function animateParticles() {
      ctx.clearRect(0, 0, canvas.width, canvas.height)

      for (let i = 0; i < particles.length; i++) {
        particles[i].update()
        particles[i].draw()

        if (i >= particles.length) i = 0
      }

      requestAnimationFrame(animateParticles)
    }

    createParticles()
    animateParticles()
  })

  return (
    <canvas
      ref={canvasRef}
      style={{
        position: 'absolute',
        height: window.innerHeight,
        width: window.innerWidth,
      }}
    />
  )
}

export default Rain
