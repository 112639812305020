import Layout from 'lib/components/Layout';
import SetProgressPage from './pages/SetProgressPage';

import { base } from 'themes';

const Home = () => (
  <Layout
    theme={base}
    disableNavbar={true}
  >
    <SetProgressPage />
  </Layout>
);

export default Home
