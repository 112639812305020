import { useState, useEffect, useRef } from 'react'
import {
  Container,
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material'
import { Send as SendIcon } from '@mui/icons-material'

import Layout from 'lib/components/Layout'
import Conversation from './Conversation'

import { createCompletion } from './data/openai'
import {
  getSession,
  setSession,
} from '../lib/sessions'


const sectionStyles = { py: 10 }

function OpenAIPage() {
  const [apiKey, setApiKey] = useState('')
  const [prompt, setPrompt] = useState('')
  const [helperText, setHelperText] = useState('')
  const [messages, setMessages] = useState([])

  const scrollRef = useRef(null)

  useEffect(() => {
    const { token } = getSession() || {}

    if (token) {
      setApiKey(token)
    }

    scrollRef.current?.scrollIntoView({ behaviour: 'smooth' })
  }, [messages])

  const onApiKeyChange = (event) => {
    setApiKey(event.target.value)

    setSession({ token: event.target.value })
  }

  const onPromptChange = (event) => {
    setPrompt(event.target.value)
  }

  const onSubmit = (event) => {
    event.preventDefault()

    if (!apiKey) {
      setHelperText('API Key is required')
    } else if (!prompt) {
      setHelperText('A message is required')
    } else {
      const conversation = [
        ...messages,
        {
          role: 'user',
          content: prompt,
        },
      ]

      setPrompt('')
      setHelperText('')
      setMessages(conversation)
      createCompletion(conversation)
        .then((response) => {
          console.log('Response: ', JSON.stringify(response, null, 2))

          setMessages(
            [
              ...conversation,
              {
                role: 'assistant',
                content: response.choices[0].message.content,
              },
            ]
          )
        })
    }
  }

  return (
    <Layout>
      <Container
        id="open-ai"
        component="section"
        sx={sectionStyles}
      >
        <TextField
          fullWidth
          id="api-key-field"
          label="OpenAI API Key"
          margin="normal"
          onChange={onApiKeyChange}
          value={apiKey}
        />

        <Conversation
          messages={messages}
          scrollRef={scrollRef}
        />

        <form
          component="form"
          onSubmit={onSubmit}
          sx={{ width: '100%' }}
          autoComplete="off"
        >
          <FormHelperText>{helperText}</FormHelperText>
          <TextField
            fullWidth
            id="prompt-field"
            label="Your message"
            aria-label="Write a prompt for the AI to respond to..."
            margin="normal"
            onChange={onPromptChange}
            placeholder="Write a prompt for the AI to respond to..."
            value={prompt}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Submit your promtp to start a conversation with OpenAI"
                    onClick={onSubmit}
                  >
                    <SendIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </form>
      </Container>
    </Layout>
  )
}

export default OpenAIPage
