import {
  BrowserRouter,
  Route,
  Routes,
} from 'react-router-dom'

import Home from './Home'
import CollectTCG from './CollectTCG'
import OpenAIPage from './OpenAIPage'
import OverlayPage from './OverlayPage'
import Parallax from './Parallax'

import './App.css'

function App() {
  console.log('enviroment: ', process.env.NODE_ENV)
  if (process.env.NODE_ENV === 'development')
    console.log('using the following setup: ', process.env)

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/tcg-me/:setCode" element={<CollectTCG />} />
        <Route path="/overlay" element={<OverlayPage />} />
        <Route path="/openai" element={<OpenAIPage />} />
        <Route path="parallax" element={<Parallax />} />

        <Route
          path="*"
          element={
            <main style={{ padding: "1rem" }}>
              <p>There's nothing here!</p>
            </main>
          }
        />
      </Routes>
    </BrowserRouter>
  )
}

export default App
