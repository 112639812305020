class Particle {
  constructor({ canvas, ctx }) {
    this.canvas = canvas
    this.ctx = ctx

    const index = Math.floor(Math.random() * 5)
    this.imageSrc = `/overlays/candy/sour${index}.png`

    this.size = Math.random() * 50 + 50

    this.x = Math.random() * canvas.width
    this.y = Math.random() * canvas.height

    this.speedX = Math.random() * 1 - 1
    this.speedY = Math.random() * 1 + 0.7
  }

  update() {
    this.x -= 0
    this.y += this.speedY

    if (this.size > 0.2) this.size -= 0.1

    if (this.y > this.canvas.height) {
      this.x = Math.random() * this.canvas.width
      this.y = 0

      this.size = Math.random() * 50 + 50
    }
  }

  draw() {
    const image = new Image()
    image.src = this.imageSrc

    this.ctx.drawImage(image, this.x, this.y, 8, 16)
  }
}

export default Particle
