import { useState, useEffect } from 'react'
import {
  Container,
  Link,
  Grid,
  Typography,
} from '@mui/material'

import { db } from 'lib/firebase'
import { onValue, ref } from 'firebase/database'
import { logAnalyticsEvent } from 'lib/firebase/analytics'

const mutopiLink = (
  <Link
    href="https://mutopi.com"
    target="_blank"
    underline="none"
    onClick={() => logAnalyticsEvent('mutopi_link_clicked')}
  >
    mutopi
  </Link>
)

const twitchLink = (
  <Link
    href="https://mutopi.com/live"
    target="_blank"
    underline="none"
    onClick={() => logAnalyticsEvent('twitch_link_clicked')}
  >
    Twitch
  </Link>
)


function About() {
  const [me, setMe] = useState({
    name: 'Agustin',
  })

  useEffect(() => {
    const query = ref(db, "/me")

    return onValue(query, (snapshot) => {
      const data = snapshot.val()

      if (snapshot.exists()) setMe(data)
    })
  }, [])

  const { name } = me

  return (
    <Container
      component="section"
      id="main"
      maxWidth="sm"
      sx={{
        height: '75vh',
        mt: {
          xs: 10,
          sm: 3,
        },
      }}
    >
      <Grid container
        height="100%"
        justifyContent="center"
        alignContent="center"
      >
        <Grid item textAlign="center">
          <Typography variant="h2">
            Hi there!
            <span className="wave">👋</span>
          </Typography>
          <Typography variant="h1">
            I'm {name}
          </Typography>
        </Grid>

        <Grid item>
          <Typography sx={{ my: 3 }} variant="body1">
            I've been working in the software development industry for 14 years, and now I'm building my own game studio from scratch, starting with {mutopiLink}. A massive-multiplayer role-playing game that takes place in the real world.
          </Typography>
          <Typography sx={{ my: 3 }} variant="body1">
            I'm doing a little bit of everything, but I mainly am a programmer and I stream all of the development live <i>(almost)</i> every day on {twitchLink}. Come join me along the ride!
          </Typography>
        </Grid>
      </Grid>
    </Container>
  )
}

export default About
