import Layout from 'lib/components/Layout'
import About from './About'
import Stream from './Stream'
import Projects from './Projects'

import { base } from 'themes';

const FOOTER_PROPS = {
  topText: 'Made with ❤️ by',
  bottomText: 'Agustin Anfuso (me)',
  url: '/',
};

const Home = () => (
  <Layout
    theme={base}
    disableNavbar={true}
    footerProps={FOOTER_PROPS}
  >
    <About />
    <Stream />
    <Projects />
  </Layout>
);

export default Home
