import { useLocation } from 'react-router-dom'
import qs from 'qs'
import { Box } from '@mui/material'

import Rain from './Rain'

const LAYOUT_TO_OVERLAY = {
  rain: (<Rain />),
  undefined: "No overlay selected",
}

function OverlayPage() {
  const { search } = useLocation()
  const { layout } = qs.parse(search, { ignoreQueryPrefix: true })

  const overlay = LAYOUT_TO_OVERLAY[layout]

  return (
    <Box>
      {overlay}
    </Box>
  )
}

export default OverlayPage
