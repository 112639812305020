import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import qs from 'qs'

import behind from './layers/behind.png'
import back from './layers/back.png'
import essence from './layers/essence.png'
import player from './layers/player.png'

const IMAGES = [
  {
    alt: 'Smoke on the back',
    src: back,
    speed: 1.5,
  },
  {
    alt: 'Player glow',
    src: behind,
    speed: .8,
  },
  {
    alt: 'Essence embedding a mysterious character',
    src: essence,
    speed: .7,
  },
  {
    alt: 'Player empowered by the essence',
    src: player,
    speed: .5,
  },
]

function Parallax() {
  const [mousePosition, setMousePosition] = useState({})
  const { search } = useLocation()

  const layers = IMAGES.map(({alt, src, speed}) => {
    const { axis } = qs.parse(search, { ignoreQueryPrefix: true })
    const x = (window.innerWidth - mousePosition.x * speed) / 100
    const y = (window.innerHeight - mousePosition.y * speed) / 100
    const axis_tranforms = {
      all: `translateX(${x}px) translateY(${y}px)`,
      x: `translateX(${x}px) translateY(0px)`,
      y: `translateX(0px) translateY(${y}px)`,
    }

    let style = { transform: axis_tranforms[axis] || axis_tranforms['x'] }

    return (
      <img src={src} style={style} alt={alt} key={alt} />
    )
  })

  return (
    <section onMouseMove={({ clientX: x, clientY: y }) => setMousePosition({x, y})}>
      {layers}
    </section>
  )
}

export default Parallax
