import { useRef, useEffect } from 'react'
import { Container } from '@mui/material'

function Stream() {
  const twitchPlayer = useRef(null)

  useEffect(() => {
    if (document.querySelector('#twitch-embed iframe')) return

    twitchPlayer.current = new window.Twitch.Player('twitch-embed', {
      autoplay: false,
      channel: 'mutopilabs',
      muted: true,
      height: '365px',
      width: '100%',
    })
  }, [])

  return (
    <Container
      disableGutters
      component="section"
      maxWidth="md"
      sx={{ py: 5, height: '100%' }}
    >
      <div ref={twitchPlayer} id="twitch-embed"></div>
    </Container>
  )
}

export default Stream
