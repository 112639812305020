import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  Typography,
} from '@mui/material'

import {
  GitHub as GitHubIcon,
  Link as LinkIcon,
  Construction as ConstructionIcon,
} from '@mui/icons-material';

import { logAnalyticsEvent } from 'lib/firebase/analytics'


const ICON_BY_LABEL = {
  code: <GitHubIcon />,
  product: <LinkIcon />,
  wip: <ConstructionIcon />,
}

const ProjectCard = ({ actions, description, image, title, url }) => {
  const hasActions = actions.length > 0
  const actionsComponent = hasActions && (
    <CardActions>
      {actions.map(({ ariaLabel, label, url }) => (
        <IconButton
          aria-label={ariaLabel}
          href={url}
          key={label}
          rel="noopener"
          target="_blank"
          onClick={() => logAnalyticsEvent('select_content', { content_type: 'project', item_id: title, item_name: label })}
        >
          {ICON_BY_LABEL[label]}
        </IconButton>
      ))}
    </CardActions>
  )

  return (
    <Card sx={{ height: 1 }}>
      <CardActionArea
        href={url}
        target="_blank"
        onClick={() => logAnalyticsEvent('select_content', { content_type: 'project', item_id: title })}
      >
        <CardMedia
          sx={{ height: 140 }}
          image={image}
          title={title}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div" color="primary">
              {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
      {actionsComponent}
    </Card>
  )
}

export default ProjectCard
